body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Modal */
.ReactModalPortal > div{
  opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  transition: opacity .2s ease-in-out;
  z-index: 9999;
}

.modal-fondo {
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
  opacity: 0;
}

.modal {
  background: white;
  border-radius: 10px;
  color: rgb(51, 51, 51);
  display: inline;
  max-height: 620px;
  max-width: 920px;
  outline: none;
  padding: 15px;
}
.navbarLinks {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}
.recharts-surface{
  max-width: 100%;
  height: 250px;
}
.recharts-wrapper{
  max-width: 100%;
  height: 200px !important;
}